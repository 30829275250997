$max-content-width: 59.6875rem;
$body-faq-margin: calc((100vw - #{$max-content-width})/2);

.faq-heading {
	font-size: 0;
	height: 0;
	opacity: 0;
	width: 0;
}

.faq {
	margin: -0.4688rem 0 0;

	@include breakpoint-up(xl) {
		margin: -5.09rem 0 0;
	}

	.faq__header {
		background-color: $banner-bg-color;
		text-align: center;

		img {
			position: relative;
			top: 0.5rem;
			width: 100%;

			@include breakpoint-up(xl) {
				top: 0.625rem;
				width: 61.0625rem;
			}
		}
	}

	&__content {
		background-image: linear-gradient($body-background 0%, $color-amour 100%);
		padding: 2.5rem var(--body-margin) 3.4375rem;

		@include breakpoint-up(xl) {
			display: grid;
			grid-column-gap: 7.8125rem;
			grid-template-columns: repeat(2, auto);
			padding: 5.3125rem $body-faq-margin 8.75rem;
		}
	}

	&__item {
		margin: 0 0 3.75rem;

		@include breakpoint-up(xl) {
			margin: 0 0 4.6875rem;
			width: 25.9375rem;

			// if four items exists, remove margin bottom for items 3 & 4
			&:nth-child(1):nth-last-child(4),
			&:nth-child(2):nth-last-child(2) {
				margin: 0 0 4.6875rem;
			}

			&:nth-child(3):nth-last-child(2),
			&:nth-child(4):nth-last-child(1) {
				margin: 0;
			}

			// if five items exists, remove margin bottom for items 5
			&:nth-child(1):nth-last-child(5),
			&:nth-child(2):nth-last-child(2),
			&:nth-child(3):nth-last-child(3),
			&:nth-child(4):nth-last-child(4) {
				margin: 0 0 4.6875rem;
			}

			&:nth-child(5):nth-last-child(1) {
				margin: 0;
			}

			// if six items exists, remove margin bottom for items 5 & 6
			&:nth-child(1):nth-last-child(6),
			&:nth-child(2):nth-last-child(2),
			&:nth-child(3):nth-last-child(3),
			&:nth-child(4):nth-last-child(4) {
				margin: 0 0 4.6875rem;
			}

			&:nth-child(5):nth-last-child(2),
			&:nth-child(6):nth-last-child(1) {
				margin: 0;
			}
		}

		&:last-child {
			margin: 0;
		}
	}

	&__question p {
		color: $body-font-color;
		font-family: $title-font;
		font-size: 1.375rem;
		font-weight: $font-weight-semi-bold;
		letter-spacing: 0.0875rem;
		line-height: 1.5454;
		margin: 0 0 1.25rem;
		padding: 0;
	}

	&__answer p {
		color: $body-font-color;
		font-family: $body-font-family;
		font-size: 1rem;
		font-weight: $font-weight-normal;
		letter-spacing: 0.0019rem;
		line-height: 1.875;
		margin: 0;
		padding: 0;
	}

	a {
		@include underline-text;
	}
}
