.rating {
	--star-fill-colour: #{$primary-color};
	--star-stroke-colour: #{$primary-color};
	align-items: baseline;
	display: flex;
	line-height: 0;

	&.rate .star {
		--star-fill-colour: #{$primary-color};
		--star-stroke-colour: #{$primary-color};
		cursor: pointer;
	}

	&.rate:hover {
		.star {
			svg path {
				fill: var(--star-fill-colour);
			}
		}

		.star:hover ~ .star {
			svg path {
				fill: none;
			}
		}
	}

	.star {
		width: 0.75rem;
		height: 0.75rem;
	}

	svg {
		height: 100%;
		pointer-events: none;
		path {
			stroke: var(--star-stroke-colour);
		}

		path:nth-child(1) {
			stroke: none;
		}
	}

	.rating__empty {
		svg path {
			fill: none;
		}
	}
	.rating__full {
		svg path {
			fill: var(--star-fill-colour);
		}
	}
	.rating__half {
		svg path {
			fill: none;
		}
		svg path:nth-child(1) {
			fill: var(--star-fill-colour);
		}
	}
}
