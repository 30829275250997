.gallery {
	margin: 2.5rem var(--body-margin);

	@include breakpoint-up(lg) {
		margin: 0;
	}
}

.gallery-slide {
	margin: 3.5rem auto 4.5rem;
	max-width: 53rem;

	@include breakpoint-up(lg) {
		margin: 5.5rem 0;
	}

	&-image {
		margin: 0.5rem auto;
		position: relative;
		text-align: center;

		&-link {
			display: block;
		}
	}

	&-image-caption {
		@extend %image-caption;
	}

	&-number {
		color: $color-grey-60;
		font-family: $title-font;
		font-size: 0.625rem;
		letter-spacing: 0.0625rem;
		line-height: 1.5;
		margin: 1.125rem 0;

		@include breakpoint-up(lg) {
			font-size: 0.75rem;
			letter-spacing: 0.075rem;
			line-height: 1.5;
			margin: 1.75rem 0;
		}

		&-current {
			color: $body-font-color;
		}
	}

	&-description {
		margin: 1rem 0;
	}

	&-cta {
		line-height: 1.8333;
		margin: 0.5rem 0;
		padding: 1.125rem 2.25rem;
		text-decoration: none !important;

		@include breakpoint-up(lg) {
			margin-top: 20px;
		}
	}
}
