.tag-empty-page {
	margin-top: -1.8125rem;
	text-align: center;

	h2 {
		margin: 0;
	}
}

.tag-empty-filter-page {
	h2 {
		margin-top: 1.625rem;
	}
}

.tag-empty-message {
	color: $body-font-color;
	font-family: $body-font-family;
	font-size: 1rem;
	letter-spacing: 0.0313rem;
	line-height: 1.5625;
	text-align: center;
}

.tag-home-link {
	@extend %load-more;
	margin-top: 4.625rem;
}
