.article {
	margin-top: 1.675rem;
}

.article-header {
	margin: 0 var(--body-margin);

	@include breakpoint-up(xl) {
		margin: 0 auto;
		max-width: 66.5rem;
	}
}

.article-body-wrapper {
	display: flex;
	flex-direction: column;
	margin: 0.75rem 0;

	@include breakpoint-up(lg) {
		flex-direction: row;
		margin: 2.5rem var(--body-margin);
	}

	.article-content,
	.article-footer,
	.article-affiliate {
		margin: 0 var(--body-margin);

		@include breakpoint-up(lg) {
			margin: 0;
		}
	}

	.article-video {
		margin: 2rem var(--body-margin);

		@include breakpoint-up(lg) {
			margin: 0 0 4.5rem;
		}
	}
}

.article-category {
	padding: 0 0 1rem;
	text-align: center;
	text-transform: uppercase;

	&-link {
		@extend %h5-style;
		color: $primary-color;
		letter-spacing: 0.05rem;
		text-decoration: underline;
		text-decoration-color: transparent;
		text-decoration-thickness: 0.0938rem;
		transition: $default-transition;

		&:hover {
			text-decoration-color: $primary-color;
		}
	}
}

.article-title {
	padding: 0;
	text-align: center;

	@include breakpoint-up(lg) {
		margin: 0 0 1.5rem;
	}

	@include breakpoint-up(xs) {
		margin: 0 0 1rem;
	}

	&.is-long-title {
		font-size: 1.375rem;
		line-height: 1.3636;

		@include breakpoint-up(lg) {
			font-size: 2.5rem;
			line-height: 1.3;
		}
	}
}

.article-metadata {
	margin-top: 1.25rem;
	text-align: center;

	@include breakpoint-up(lg) {
		margin-top: 2rem;
	}
}

.article .author-byline,
.article-date,
.article-readtime {
	@extend %h5-style;
}

.article .author-byline {
	font-size: 0.75rem;
	font-weight: $font-weight-semi-bold;
	letter-spacing: 0.02rem;
	line-height: 1.6666;
	margin: 0.5rem auto;
	width: 18.75rem;

	@include breakpoint-up(sm) {
		width: unset;
	}

	@include breakpoint-up(lg) {
		margin: 1.125rem 0 0.375rem;
	}

	&-author {
		display: inline;
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: underline;
		text-decoration-thickness: 0.0938rem;
		text-transform: uppercase;
		transition: $default-transition;

		&:hover {
			color: $primary-color;
		}
	}
}

.article-date {
	color: $color-dimgrey;
	font-size: 0.625rem;
	letter-spacing: 0.0156rem;
	margin: 0.3125rem 0 1.875rem;

	@include breakpoint-up(lg) {
		font-size: 0.75rem;
		margin: 0.625rem 0 2.5rem;
	}
}

.article-featured-image-wrapper {
	margin: 2rem auto;
	max-width: $max-feature-image-width;

	@include breakpoint-up(md) {
		margin: 1rem var(--body-margin) 2.5rem;
	}

	@include breakpoint-up(lg) {
		margin: 0 auto 2.5rem;
	}
}

.article-featured-image {
	position: relative;

	&::after {
		content: '';
		display: block;
		padding-bottom: 67%;
	}

	img {
		display: block;
		height: 100%;
		object-fit: cover;
		position: absolute;
		width: 100%;
	}

	&-caption {
		@extend %image-caption;
		height: auto;
		letter-spacing: 0.03rem;
		margin: 0.5rem var(--body-margin);
		word-break: break-word;

		@include breakpoint-up(md) {
			margin: 0.5rem 0;
		}
	}
}

.article-body {
	margin: 0;

	@include breakpoint-up(lg) {
		flex: 1 0 33.75rem;
		margin-right: 5%;
		margin-top: 1.5rem;
		max-width: $max-feature-image-width;
		width: 100%;
	}

	@include breakpoint-up(xl) {
		margin-right: 7.75rem;
	}
}

.article-content,
.gallery-slide-description,
.recipe-description,
.recipe-step-content {
	@extend %body-font;

	> * {
		width: 100%;
	}

	> p {
		width: 100%;
	}

	a {
		text-decoration: underline;
		text-decoration-color: $primary-color;
		text-underline-offset: 0.0625rem;
		transition: $default-transition;

		&:hover {
			color: $primary-color;
		}
	}

	/* Pullquote styling */
	blockquote:not(.tiktok-embed) {	// May need to extend this to Instagram Embeds
		font-family: $body-font-family;
		font-size: 1.5rem;
		font-weight: $font-weight-semi-bold;
		letter-spacing: 0.019rem;
		line-height: 1.5;
		margin: 3.125rem auto;
		max-width: 28rem;
		position: relative;
		text-align: center;
		width: 100%;

		&::after,
		&::before {
			border-bottom: 0.44rem solid $body-font-color;
			content: ' ';
			display: block;
			font-size: 6rem;
			margin: auto;
			position: relative;
			width: 25%;
		}

		&::before {
			border-bottom: unset;
			border-top: 0.44rem solid $body-font-color;
		}

		> *,
		> p {
			display: block;
			font-family: $body-font-family;
			font-size: 1.5rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.019rem;
			line-height: 1.5;
			margin: 1.5rem;
			position: relative;

			&::after,
			&::before {
				content: '\201C';
				font-family: $body-font-family;
			}

			&::after {
				content: '\201D';
			}
		}

		a {
			text-decoration: underline;
			text-decoration-color: $primary-color;
			text-underline-offset: 0.0625rem;
			transition: $default-transition;

			&:hover {
				color: $primary-color;
			}
		}

	}

	&.tiktok-embed {
		@include breakpoint-up(xl) {
			margin: 3.125rem 0;
			max-width: 20rem !important;
			width: auto;

			iframe {
				margin: 1.5rem auto;
			}
		}

		iframe {
			margin: 1.5rem 0;
		}
	}

	h3,
	h2 {
		@extend %h3-style;

		letter-spacing: 0.063rem;
		margin: 2.188rem 0 0.5rem;
		text-transform: none;

		@include breakpoint-up(xl) {
			margin-top: 2.8125rem;
		}
	}

	.wp-caption,
	img {
		align-self: center;
		height: auto !important;
		margin: 2.5rem auto;
		max-width: 100% !important;
		padding: 0;
		text-align: center;
		width: auto;

		@include breakpoint-up(xl) {
			align-self: flex-start;
			margin: 2.5rem 0;
			max-width: 100%;
		}
	}

	img {
		display: block;

		/* As long as the image isn't loaded set width to 0 pixel, so aspect ratio knows to take width from height and not vice versa */
		.lazyload {
			width: 0;
		}
	}

	.wp-caption {
		display: block;
		width: auto !important;
	}

	/* stylelint-disable-next-line selector-no-qualifying-type */
	img[data-sizes='auto'] {
		display: block;
		width: 100%;
	}

	.wp-caption-text {
		@extend %image-caption;
		margin: 0.5rem 0 0;
		max-width: 100%;
		overflow: hidden;
		padding-left: 0.0938rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.article-end-ribbon {
		background: $secondary-color;
		height: 0.5rem;
		margin: 3.25rem auto 2.875rem;
		width: 4.5rem;
	}

	iframe {
		max-width: 100%;
	}

	.youtube-embed {
		padding-bottom: 56%;
		position: relative;
		text-align: left;
		width: 100%;

		iframe {
			height: 100%;
			overflow: hidden;
			position: absolute;
			width: 100%;
		}
	}

	.tiktok-embed,
	.youtube-embed {
		margin: 2rem 0;

		@include breakpoint-up(md) {
			margin: 2.5rem 0;
		}
	}

	.spotify-embed,
	.twitter-tweet {
		padding: 2rem 0 !important;

		@include breakpoint-up(md) {
			padding: 2.5rem 0 !important;
		}
	}

	.twitter-tweet {
		margin-bottom: 0 !important;
		margin-top: 0 !important;
	}

	.ad-wrapper {
		margin: 2.5rem auto 3.125rem;

		@include breakpoint-up(md) {
			margin: 3.75rem auto;
		}
	}

	/* stylelint-disable-next-line selector-class-pattern */
	.corusPlayer-container {
		height: auto !important;
		width: 100% !important;
	}
}

.post-tags {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 1rem var(--body-margin) 2rem;
	padding: 0;

	@include breakpoint-up(md) {
		margin: 3rem var(--body-margin);
	}

	@include breakpoint-up(lg) {
		margin: 4rem 0;
	}

	.post-tag {
		list-style-type: none;
		margin: 1rem 1rem 1rem 0;

		.button-primary {
			@include focus-outline($primary-color);
			background-color: $primary-color;
			border: 0.125rem solid $primary-color;
			border-radius: 0.0625rem;
			font-size: 0.75rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.013rem;
			line-height: 1.6666;
			padding: 0.625rem 1rem;
			text-decoration: none;
			word-break: keep-all;

			&:hover,
			&:active {
				background-color: $body-background;
				color: $body-font-color;
			}
		}
	}
}

.article-affiliate-copy {
	color: $affiliate-copy-color;
	font-family: $body-font-family;
	font-size: 0.875rem;
	letter-spacing: 0.0156rem;
	line-height: 1.8857;
	margin: 1.25rem 0 2.5rem;

	@include breakpoint-up(md) {
		font-size: 1rem;
		margin: 1.875rem 0;
	}
}

.article-end-ribbon {
	background: $secondary-color;
	height: 0.5rem;
	margin: 3rem auto;
	width: 4.5rem;

	@include breakpoint-up(md) {
		margin: 5rem auto;
	}
}

.article-content-image {
	margin: 0;
	text-align: center;
	width: 100%;

	.wp-caption & {
		text-align: left;
	}
}


.article-content {
	.size-full {
		width: 100%;
	}
}
