.server-error-500 {
	&-content-wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-family: $title-font;
		height: 50vh;
		justify-content: center;
		margin: 0 var(--body-margin);
		min-height: 37.5rem;
		text-align: center;

		@include breakpoint-up(lg) {
			margin-top: -4.5rem;
		}
	}

	&-title-text {
		font-size: 2.1875rem;
		font-weight: $font-weight-bold;
		letter-spacing: 0;
		line-height: 1.2;
		text-align: center;
		text-transform: uppercase;
	}

	&-description-text {
		font-family: $body-font-family;
		font-size: 1.125rem;
		font-weight: $font-weight-medium;
		line-height: 1.8888;
		margin: 2.8125rem 0 1.875rem;
	}

	&-error-code {
		color: $color-dimgrey;
		font-size: 1.25rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0;
		line-height: 1.4;
		text-transform: uppercase;
	}

	&-button {
		align-items: center;
		background-color: $primary-color;
		border: 0.125rem solid $primary-color;
		border-radius: 0;
		color: $body-background;
		cursor: pointer;
		display: flex;
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		height: 4rem;
		justify-content: center;
		letter-spacing: 0.045rem;
		line-height: 1.5;
		margin-top: 1.875rem;
		padding: 0 1.6875rem;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		transition: background-color .3s ease-out;

		&:hover,
		&:active {
			background-color: $body-background;
			color: $primary-color;
		}
	}
}
