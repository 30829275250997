$sign-up-btn-width-mobile: 4.875rem;

.newsletter {
	display: flex;
	flex-direction: column;
	padding: 0 var(--body-margin);

	@include breakpoint-up(lg) {
		flex-direction: row;
	}

	.newsletter-block {
		background-color: $primary-color;
		width: 100%;

		&__title {
			margin-bottom: 2.813rem;
			width: 100%;
			color: $secondary-color;

			@include breakpoint-between(lg, xl) {
				width: 40% !important;
			}

			@include breakpoint-up(lg) {
				margin-bottom: 0;
				width: 30%;
			}

			h2 {
				font-size: 1.5rem;
				letter-spacing: 0.031rem;
				line-height: 1.3753;
				margin: 0;
				padding: 0;

				@include breakpoint-up(lg) {
					font-size: 2.125rem;
					line-height: 1.4414;
				}
			}
		}

		&__terms {
			margin-top: 2.2rem;
			text-align: left;

			@include breakpoint-up(lg) {
				margin-top: 1.5rem;
			}

			p,
			a {
				font-family: $title-font;
				color: $button-primary-color;
				font-size: 0.75rem;
				font-weight: 500;
				letter-spacing: 0.019rem;
				line-height: 1.4173;
			}

			a {
				text-decoration: underline;
			}
		}

		&__form {
			width: 100%;

			@include breakpoint-up(lg) {
				padding-left: 5rem;
				padding-top: 2.813rem;
				width: 70%;
			}

			input,
			p,
			a {
				font-family: $title-font;
				color: $button-primary-color;
			}

			.email-submit {
				display: inline-block;
			}
		}

		&__form-wrapper {
			align-items: baseline;
			justify-content: center;
			padding: 2rem;
			text-align: left;

			@include breakpoint-up(lg) {
				display: flex;
				padding: 4rem;
			}
		}

		&__input-fields-wrapper {
			position: relative;

			.email-input {
				border-bottom: 0.0625rem solid $secondary-color;

				input {
					appearance: none;
					background-color: transparent;
					border: 0;
					border-radius: 0;
					font-size: 1rem;
					font-weight: 400;
					padding-bottom: 0.625rem;
					padding-left: 0;
					width: calc(100% - $sign-up-btn-width-mobile - 2%);

					@include breakpoint-up(lg) {
						font-size: 1.375rem;
						letter-spacing: 0.037rem;
					}

					&:focus {
						box-shadow: none;
						outline-style: none;
					}
				}

				::placeholder {
					color: $color-grey-75;
					opacity: 1;
				}

				:input-placeholder {
					color: $color-grey-75;
				}

				::input-placeholder {
					color: $color-grey-75;
				}
			}

			.email-submit {
				position: absolute;
				right: 0;
				top: -0.7rem;

				@include breakpoint-up(lg) {
					top: -1.2rem;
				}

				input {
					appearance: none;
					background-color: $secondary-color;
					border: 0;
					border-radius: 0;
					color: $body-font-color;
					font-size: 0.625rem;
					font-weight: 600;
					height: 2.438rem;
					letter-spacing: 0.05rem;
					line-height: 1.4;
					text-align: center;
					text-transform: uppercase;
					width: $sign-up-btn-width-mobile;

					@include breakpoint-up(lg) {
						font-size: 0.75rem;
						font-weight: 600;
						height: 3.375rem;
						letter-spacing: .05rem;
						line-height: 1.4166;
						text-align: center;
						width: 7.4319rem;
					}
				}
			}

			.invalid-msg p {
				display: none;
				font-size: 0.75rem;
				font-weight: 600;
				letter-spacing: 0.019rem;
				margin: 0;
			}
		}

		&__success {
			padding: 4rem 3rem;

			@include breakpoint-up(lg) {
				padding: 0;
			}

			p {
				font-family: $title-font;
				font-size: 1.25rem;
				letter-spacing: .0313rem;
				line-height: 1.65;
				text-align: center;
				color: $button-primary-color;

				@include breakpoint-up(lg) {
					font-size: 1.5625rem;
				}
			}
		}

		&__error {
			align-items: center;
			display: flex;
			margin-top: 1rem;

			p {
				font-size: 0.75rem;
				font-weight: 400;
				letter-spacing: 0.019rem;
				line-height: 1.4173;
				margin: 0;
			}

			.error-icon {
				background-color: $button-primary-color;
				border-radius: 50%;
				display: inline-block;
				height: 1rem;
				margin-right: 1.25rem;
				position: relative;
				width: 1rem;

				@include breakpoint-up(lg) {
					margin-right: 0.625rem;
				}

				span {
					color: $primary-color;
					font-size: 0.75rem;
					font-weight: 900;
					left: 50%;
					line-height: 1.4173;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	&.boxed {
		@include breakpoint-up(lg) {
			padding: 0;
		}

		.newsletter-block {
			&__title {
				padding-right: 1.375rem;
				@include breakpoint-up(lg) {
					padding: 4rem 0 2.6875rem 2.8125rem;
				}

				h2 {
					font-size: 1.5rem;
					letter-spacing: 0.03125rem;
					line-height: 1.375;
					@include breakpoint-up(lg) {
						font-size: 1.25rem;
						letter-spacing: 0.03125rem;
						line-height: 1.875;
					}
				}
			}

			&__terms {
				margin-top: 2.875rem;
				@include breakpoint-up(lg) {
					margin-bottom: 0;
					margin-top: 1.375rem;
				}

				a,
				p {
					font-size: 0.75rem;
					letter-spacing: 0.01875rem;
					line-height: 1.445;
					@include breakpoint-up(lg) {
						font-size: 0.75rem;
						font-weight: 400;
						letter-spacing: 0.01875rem;
						line-height: 1.2191;
					}
				}

				a {
					@include breakpoint-up(lg) {
						font-weight: 500;
						line-height: 1.445;
					}
				}
			}

			&__form {
				@include breakpoint-up(lg) {
					padding: 4.0625rem 4.0625rem 4.0625rem 1.6875rem;
				}
			}

			&__form-wrapper {
				padding: 1.75rem 1.4375rem;
				@include breakpoint-up(lg) {
					padding: 0;
				}
			}

			&__input-fields-wrapper {
				.email-input {
					input {
						letter-spacing: 0.03625rem;
						line-height: 0.7727;
						@include breakpoint-up(lg) {
							font-size: 1.25rem;
							letter-spacing: 0.03625rem;
							line-height: 1.85;
						}
					}
				}

				.email-submit {
					input {
						font-size: 0.625rem;
						letter-spacing: 0.05rem;
						line-height: 1.445;
						@include breakpoint-up(lg) {
							font-size: 0.75rem;
							letter-spacing: 0.05rem;
							line-height: 1.445;
							padding: 0;
							width: 5.1875rem;
						}
					}
				}
			}

			&__error {
				@include breakpoint-up(lg) {
					margin-bottom: 1.25rem;
				}
			}

			&__success {
				@include breakpoint-up(lg) {
					padding: 4rem 3rem;
				}
			}
		}
	}
}
