.homepage {
	background: $site-bg-gradient;
	padding-bottom: 0.0625rem;

	@include breakpoint-between(xs, md) {
		.ad-wrapper {
			margin: 2.75rem auto;
		}
	}
}

.homepage-newest-content-container {
	margin: 2.8125rem 0;

	@include breakpoint-up(xl) {
		margin: 5.0625rem 0;
	}
}

.homepage-tag-sections-container {
	padding: 0 var(--body-margin);

	.tag-section {
		&--title {
			font-family: $accent-font;
			font-size: 1.375rem;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0775rem;
			line-height: 1.1818;
			padding-bottom: 1rem 0;
			text-transform: uppercase;

			@include breakpoint-up(sm) {
				font-size: 1.75rem;
				letter-spacing: 0.1206rem;
				line-height: 1.4642;
				padding: 1.375rem 0 0.625rem;
			}

			@include breakpoint-up(md) {
				padding-bottom: 0;
			}
		}
	}

	.post-block-grid {
		padding: 0;
	}

	.see-more-container {
		margin: 4rem 0 4.625rem;
		text-align: center;

		@include breakpoint-up(sm) {
			margin: 5.1875rem 0 4.375rem;
		}

		@include breakpoint-up(lg) {
			margin: 8.5rem 0 7.5rem;
		}

		.see-more {
			@extend %load-more;
		}
	}

}

.redirected-message {
	border: 0.0625rem solid $body-font-color;
	border-radius: 0.0625rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 2rem var(--body-margin);
	padding: 1rem;

	
	@include breakpoint-up(lg) {
		margin: -2.5rem var(--body-margin) 2.5rem;
		padding: 1.5rem;
	}

	&-container {
		flex: 1 1 auto;
	}

	&-text {
		color: $body-font-color;
		display: block;
		font-family: $title-font;
		font-size: 0.875rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0469rem;
		line-height: 1.5;
		margin: 0 auto;
		text-align: center;
		width: 14rem;

		@include breakpoint-up(sm) {
			width: 22rem;
		}
		
		@include breakpoint-up(md) {
			width: auto;
		}

		@include breakpoint-up(lg) {
			font-size: 1rem;
			letter-spacing: 0.0625rem;
		}
	}

	&-close {
		cursor: pointer;
		display: block;
		flex: 0 1 1.375rem;
		position: relative;
		transition: height $default-transition;
		width: 1.375rem;

		&:hover {
			&::before,
			&::after {
				height: 0.875rem;

				@include breakpoint-up(lg) {
					height: 1.625rem;
					top: 0.0625rem;
				}
			}
		}
	}

	&-close::before,
	&-close::after {
		background-color: $body-font-color;
		content: ' ';
		height: 0.875rem;
		left: 0.5625rem;
		position: absolute;
		top: 0.3125rem;
		width: 0.1563rem;

		@include breakpoint-up(lg) {
			height: 1.5rem;
			left: 0.4375rem;
			top: 0.125rem;
		}
	}

	&-close::before {
		transform: rotate(45deg);
	}

	&-close::after {
		transform: rotate(-45deg);
	}
}
