.archive {
	--bottom-padding: 6rem;
	display: flex;
	flex-direction: column;
	font-family: $accent-font;
	padding-bottom: var(--bottom-padding);

	&__content {
		position: relative;

		&::before {
			background-color: $color-amour;
			content: '';
			height: calc(100% + var(--bottom-padding));
			position: absolute;
			width: 100%;
			z-index: -1;
		}
	}

	.advertisement {
		align-self: center;
	}

	.tag-header {
		align-items: center;
		background-image: linear-gradient($body-background 0%, $body-background 50%, $color-amour 100%);
		display: flex;
		flex-direction: column;
		padding: 0 var(--body-margin) 3rem;
		text-align: center;

		@include breakpoint-up(md) {
			padding-bottom: 3.75rem;
		}
		@include breakpoint-up(lg) {
			padding-bottom: 5rem;
		}
	}

	.tag-divider {
		border-top: 0.0625rem solid $color-grey-85;
		margin-bottom: 2.1875rem;
		margin-top: 1.875rem;
		max-width: 53rem;
		width: 100%;
	}

	.tag-head {
		&__title {
			letter-spacing: 0.1719rem;
			margin: 1.675rem 0;
			max-width: 53rem;
			overflow-wrap: anywhere;
			text-transform: uppercase;

			@include breakpoint-between(xs, sm) {
				margin-top: 2.5rem;
			}
			@include breakpoint-between(sm, lg) {
				margin-top: 3.75rem;
			}
		}

		&__description {
			font-size: 1rem;
			letter-spacing: 0.03125rem;
			line-height: 1.5625;
			max-width: 53rem;

			a {
				@include underline-text;
			}
		}
	}

	.tag-filter {
		align-content: center;
		display: flex;
		flex-direction: column;

		&__heading {
			font-size: 0.875rem;
			font-weight: 600;
			letter-spacing: 0.06rem;
			text-transform: uppercase;
		}

		&__group {
			display: flex;
			justify-content: center;
			margin-top: 1.25rem;

			@include breakpoint-up(lg) {
				margin-top: 1.875rem;
			}
		}

		&__field:not(:last-child) {
			margin-right: 5.375rem;
		}

		&__field label {
			font-size: 0.875rem;
			letter-spacing: 0.06rem;
			margin-left: 0;
		}

		&__field input {
			height: 0;
			opacity: 0;
			position: absolute;
			width: 0;

			&:focus-visible {
				height: 1rem;
				margin-left: 0;
				margin-top: 0.5625rem;
				opacity: 1;
				width: 1rem;
			}
		}

		&__field .checkbox {
			border: 0.0625rem solid $color-grey-35;
			border-radius: 0.15rem;
			display: inline-block;
			height: 1rem;
			margin-right: 0.78125rem;
			position: relative;
			top: 0.1875rem;
			width: 1rem;

			&::before {
				border-bottom: 0.125rem solid #fff;
				border-left: 0.125rem solid #fff;
				content: '';
				display: none;
				height: 0.2rem;
				left: 0.0625rem;
				position: absolute;
				top: 0.19rem;
				transform: rotate(-45deg);
				width: 0.5rem;
			}
		}

		// stylelint-disable-next-line selector-max-compound-selectors
		&__field input:checked ~ label .checkbox {
			background-color: #000;
			border: 0.125rem solid #000;

			// stylelint-disable-next-line selector-max-compound-selectors
			&::before {
				display: block;
			}
		}
	}

	.tag-footer {
		display: flex;
		justify-content: center;
		margin-top: 7.5rem;

		.load-more {
			@extend %load-more;

			&-link {
				@extend %load-more-link;
			}
		}
	}

	.tag-footer.hide-button {
		display: none;
	}
}
