.all-shows-filters-desktop {
	display: none;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2rem;
	margin-top: 3.625rem;
	padding: 0;

	@include breakpoint-up(sm) {
		display: flex;
	}

}

.all-shows-filters-mobile {
	display: block;

	@include breakpoint-up(sm) {
		display: none;
	}
}
