$arrow-height: 3.75rem;

.video-drawer {
	background: $black-bg-color;
	font-family: $title-font;
	margin-bottom: 3.375rem;
	margin-top: 2.375rem;
	overflow: hidden;
	padding: 1.75rem 0 3.375rem;
	position: relative;
	width: 100%;

	@include breakpoint-up(sm) {
		padding: 2.375rem 0 6.125rem;
	}

	@include breakpoint-up(lg) {
		padding: 2.625rem 0 5.6875rem;
	}

	@include breakpoint-up(xl) {
		padding-top: 4.5rem;
	}


	@include breakpoint-up(lg) {
		.slick-arrow.slick-disabled {
			display: none !important;
		}

		.slick-arrow {
			@include hide-text;
			display: inline-block !important;
			height: 3.25rem;
			position: absolute;
			top: calc(42.5% - calc(#{$arrow-height} / 2));	// vertically center arrows with respect to padding of the parent.
			transition: all $default-transition;
			width: 2.5rem;
			z-index: 1;

			&:hover {
				transform: scale(1.2);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.125rem $body-background;
			}

		}

		.slick-next {
			right: -2.375rem;

			&::after {
				@include css-arrow(right, 0.25rem solid $body-background, 2rem, true);
				content: '';
				transition: $default-transition;
			}
		}

		.slick-prev {
			left: -3.125rem;

			&::after {
				@include css-arrow(left, 0.25rem solid $body-background, 2rem, true);
				content: '';
				transition: $default-transition;
			}
		}
	}

	@include breakpoint-up(xxl) {
		.slick-arrow .slick-next {
			right: -16rem;
		}
	}

	&--title {
		color: $body-background;
		font-size: 1.25rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0669rem;
		line-height: 1.45;
		margin-bottom: 2.125rem;

		@include breakpoint-between(xs, lg) {
			span {
				display: block;
			}
		}

		@include breakpoint-up(sm) {
			font-size: 1.75rem;
			letter-spacing: 0.0938rem;
			line-height: 1.4642;
			margin-bottom: 3.5rem;
		}
	}

	&--wrapper {
		margin: 0 var(--body-margin);
		position: relative;

		@include breakpoint-up(xl) {
			margin: 0 auto;
			max-width: $max-content-well-width;
		}
	}

	&--grid {
		.slick-slide > div {
			margin-right: 0.625rem;

			@include breakpoint-up(md) {
				margin-right: 1rem;
			}
		}

		[aria-hidden = true] {
			opacity: 0.5;
			pointer-events: none;
		}

		.slick-list {
			overflow: visible;
		}
	}

	.slick-arrow {
		display: none;
	}
}
