.featured-image-container {
	display: flex;
	flex-direction: column;
	font-family: $title-font;
	position: relative;
	text-align: center;

	@include breakpoint-up(xl) {
		padding: 0 var(--body-margin);
	}
}

.featured-image {
	line-height: 0;

	&-metabox {
		background: $body-background;
		margin: -3rem var(--body-margin) 0;
		padding: 1.25rem;
		z-index: 99;

		@include breakpoint-up(md) {
			bottom: 10%;
			left: 1%;
			max-width: 26.26rem;
			padding: 2rem;
			position: absolute;
		}

		@include breakpoint-between(md, lg) {
			max-width: 20rem;
		}

		@include breakpoint-up(xl) {
			bottom: 3.6875rem;
			left: 3.125rem;
		}
	}

	&-tag {
		font-family: $title-font;
		font-size: 0.5625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.05rem;
		line-height: 2.1111;
		margin: 0 auto 0.85rem;
		text-transform: uppercase;
		width: fit-content;

		@include breakpoint-up(sm) {
			margin: 0 auto 0.5rem;
		}

		a {
			color: $primary-color;

			&:hover {
				text-decoration-line: underline;
			}
		}
	}

	&-title {
		color: $matte-black;
		font-size: 1.25rem;
		font-weight: $font-weight-semi-bold;
		letter-spacing: 0.0625rem;
		line-height: 1.3;
		max-width: 23rem;

		@include breakpoint-up(sm) {
			font-size: 1.5rem;
			letter-spacing: 0.1044rem;
			line-height: 1.2166;
		}

		@include breakpoint-up(xl) {
			line-height: 1.4166;
		}

		&:hover {
			@extend %content-underline;
		}

	}

	&-desc {
		color: $color-dimgrey;
		font-family: $body-font-family;
		font-size: 0.8125rem;
		letter-spacing: 0;
		line-height: 1.5384;
		margin: 0.625rem 0 0.8125rem;

		@include breakpoint-up(xl) {
			font-size: 0.875rem;
			line-height: 1.6428;
		}
	}
}
