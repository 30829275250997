.post-block-grid {
	align-items: end;
	background-color: $color-amour;
	display: grid;
	grid-auto-flow: row dense;
	grid-gap: var(--grid-gap);
	grid-template-columns: repeat(var(--grid-columns), 1fr);
	justify-items: center;
	padding: 0 var(--body-margin);

	@include breakpoint-between(lg, xl) {
		padding: 0 3.125rem;
	}

	&-title {
		@extend %h3-style;
		text-transform: uppercase;

		@include breakpoint-up(lg) {
			font-size: 1.5rem;
			letter-spacing: 0.1031rem;
			line-height: 1.4583;
			margin-bottom: 2.5rem;
		}
	}

	&.loader-grid {
		margin: var(--grid-gap);
		margin-left: 0;
		margin-right: 0;
	}

	.post-block {
		align-self: flex-start;
		max-width: 26.625rem;
		min-width: 19rem;

		@include breakpoint-between(sm, md) {
			max-width: 26.625rem;
		}

		@include breakpoint-between(md, lg) {
			max-width: 26.5rem;
		}

		@include breakpoint-between(lg, xl) {
			max-width: 35.1875rem;
			min-width: 18.25rem;
		}

		&__image-container {
			min-height: 10.4375rem;
			min-width: 10.4375rem;

			@include breakpoint-up(sm) {
				min-height: 13.3125rem;
				min-width: 13.3125rem;
			}
		}

		&__image-container img {
			@include breakpoint-between(sm, md) {
				height: 13.3125rem;
				width: 13.3125rem;
			}

			@include breakpoint-between(lg, xl) {
				height: 13.3125rem;
				width: 13.3125rem;
			}

			@include breakpoint-up(xl) {
				height: 13.3125rem;
				width: 13.3125rem;
			}
		}
	}
}
