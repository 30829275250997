.all-shows-filter {
	color: $matte-black;
	display: none;
	position: relative;

	@include breakpoint-up(sm) {
		display: block;
	}

	&.popup-open {
		.all-shows-filter-header {
			color: $primary-color;
		}

		.all-shows-filter-toggle {
			border-color: $primary-color;
		}
	}

	&-header {
		cursor: pointer;
		transition: $default-transition;

		&:hover {
			color: $primary-color;

			.all-shows-filter-toggle {
				border-color: $primary-color;
			}
		}
	}

	&-selection {
		font-family: $title-font;
		font-size: 0.875rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0625rem;
		margin: 1.25rem 0.875rem 1.25rem 0;
		text-transform: uppercase;
	}

	&-title {
		font-family: $title-font;
		font-size: 0.875rem;
		font-weight: $font-weight-bold;
		letter-spacing: 0.0625rem;
		margin: 1.25rem 0.625rem 1.25rem 0;
		text-transform: uppercase;
	}

	&-toggle {
		content: '';
		display: inline-block;
		transition: $default-transition;
		user-select: none;

		&.down {
			@include css-arrow(down, 0.0938rem solid $body-font-color, 0.5rem, false);
		}

		&.up {
			@include css-arrow(up, 0.0938rem solid $body-font-color, 0.5rem, false);
		}
	}

	&-popup {
		background: $body-background;
		border-radius: 0.0625rem;
		box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.2);
		color: $matte-black;
		margin-top: 0.875rem;
		position: absolute;
		width: 21.75rem;
		z-index: 100;
	}

	&-list {
		display: grid;
		gap: 1rem 1rem;
		grid-auto-flow: column;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		justify-items: center;
		margin: 1.5rem 1rem 2.5rem;
		padding: 0;
	}

	&-item {
		align-items: center;
		display: flex;
		font-family: $title-font;
		font-size: 0.75rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0519rem;
		line-height: 1.5;
		list-style: none;
		padding-right: 0;
		text-transform: uppercase;
		width: 4rem;
	}

	&-checkbox {
		border: 0.0625rem solid $matte-black;
		border-radius: 50%;
		display: inline;
		height: 1rem;
		margin-top: 0;
		padding: 0;
		position: relative;
		width: 1rem;

		&.checked {
			background-color: $color-grey-35;
			border: 0;
		}
	}

	&-item-name {
		margin-left: 0.5rem;
	}

	&-item-actions {
		border-top: 0.0625rem solid $color-grey-85;
		display: flex;
		justify-content: flex-end;
		margin: 0 1.5rem 1.625rem;
		padding-top: 1.625rem;
	}

	&-show {
		@extend %button-primary;
		border-radius: 0.0625rem;
		font-size: 0.625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0362rem;
		line-height: 1.5;
		padding: 0.625rem 1.75rem;
	}

	&-close-action {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 0 auto;
		width: 100%;

		&-item {
			display: flex;
			flex: 1 1;
			justify-content: flex-end;
			padding: 1.25rem 1.625rem 0 0;
		}

		&-close-icon {
			cursor: pointer;
			height: 1rem;
			padding: 0;
			position: relative;
			transition: height $default-transition;
			width: 1rem;
		}

		&-close-icon::before,
		&-close-icon::after {
			background-color: $matte-black;
			content: ' ';
			height: 1.25rem;
			left: 0.5rem;
			position: absolute;
			width: 0.125rem;
		}

		&-close-icon::before {
			transform: rotate(45deg);
		}

		&-close-icon::after {
			transform: rotate(-45deg);
		}

		&-title {
			font-family: $title-font;
			font-size: 0.6875rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0475rem;
			line-height: 1.4545;
			text-transform: uppercase;
		}
	}
}

.all-shows-filters-mobile {
	&-overlay {
		background-color: rgba(0, 0, 0, 0.2);
		bottom: 0;
		display: none;
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	.all-shows-filter {
		display: block;
		@include breakpoint-up(sm) {
			display: none;
		}

		&-header {
			padding-right: .625rem;
			text-align: right;
		}

		&-popup {
			left: 0;
			position: fixed;
			top: 6rem;
			width: 100vw;
		}

		&-list {
			gap: 1rem 1.5rem;
			margin: 1.5rem 3rem 2.5rem;
		}

		&-popup-header {
			border-bottom: 0.0625rem solid $color-grey-85;
			display: flex;
			flex-direction: row;
			margin: 0 1.5rem 1.625rem;
			padding: 1.625rem 0;
		}

		&-close-action {
			&-item {
				display: flex;
				flex: 1 1;
				justify-content: center;
				padding: 0;
			}
		}
	}
}

.all-shows-filter-open {
	// disable scrolling when filter is open
	overflow: hidden;

	// set header z-index so it shows up below the overlay
	.header {
		z-index: 0;
	}

	.all-shows-filters-mobile-overlay {
		display: block;
	}
}
