.right-rail {
	margin: 0 var(--body-margin);
	text-align: center;

	@include breakpoint-up(lg) {
		flex: 0 1 18.75rem;
		margin: 0;
		padding: 0;
	}

	.right-rail-ad-wrapper {

		@include breakpoint-up(lg) {
			margin-top: 0;
			position: sticky;
			top: 0.75rem;
		}
	}
}
