.recipe-filters-modal {
	$modal-header-height: 5rem;
	$modal-footer-height: 6.375rem;

	background: $body-background;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 100;

	&-content {
		height: 100%;
		position: relative;
	}

	&-header,
	&-body,
	&-footer {
		padding: 0 var(--body-margin);
	}

	&-header {
		align-items: center;
		border-bottom: 0.0625rem solid $color-grey-85;
		display: flex;
		flex-direction: row;
		height: $modal-header-height;
		position: absolute;
		width: 100%;

		&-item {
			display: flex;
			flex: 1;
			justify-content: center;
		}
	}

	&-body {
		height: calc(100% - #{$modal-header-height} - #{$modal-footer-height});
		overflow-y: auto;
		position: absolute;
		top: $modal-header-height;
		width: 100%;
	}

	&-footer {
		background: $body-background;
		border-top: 0.0625rem solid $color-grey-85;
		bottom: 0;
		display: flex;
		flex-direction: column;
		height: $modal-footer-height;
		justify-content: center;
		position: absolute;
		width: 100%;
	}

	&-close {
		cursor: pointer;
		height: 1rem;
		margin-right: auto;
		padding: 0;
		position: relative;
		transition: height $default-transition;
		width: 1rem;
	}

	&-close::before,
	&-close::after {
		background-color: $recipe-filter-color;
		content: ' ';
		height: 1.25rem;
		left: 0.5rem;
		position: absolute;
		width: 0.125rem;
	}

	&-close::before {
		transform: rotate(45deg);
	}

	&-close::after {
		transform: rotate(-45deg);
	}

	&-title {
		font-family: $title-font;
		font-size: 0.6875rem;
		font-weight: $font-weight-semi-bold;
		letter-spacing: 0.0475rem;
		line-height: 1.4545;
		text-transform: uppercase;
	}

	&-clear {
		font-family: $title-font;
		font-size: 0.625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0362rem;
		line-height: 1.6;
		margin-left: auto;
		padding: 0;
		text-decoration: underline;
	}

	&-save {
		@extend %button-primary;
	}
}

.modal-open {
	overflow: hidden;
	
	.main {
		height: 100%;
		overflow: hidden;
		top: 0;
		transform: translate3d(0, 0, 0.0625rem);
		z-index: 1;
	}
}

.recipe-filters-mobile-filter {
	.recipe-filter-list {
		border-bottom: 0.0625rem solid $color-grey-85;
		padding-bottom: 2.5rem;
	}

	&:last-child {
		.recipe-filter-list {
			border-bottom: 0;
		}
	}
}
