.skip-to-content-link {
	background: $color-grey-85;
	font-size: 0.8rem;
	font-weight: $font-weight-medium;
	left: 25%;
	line-height: 1.25;
	padding: 0.6rem 1rem;
	position: absolute;
	top: 0.2rem; // to show top outline as well
	transform: translateY(-100%);
	transition: transform $default-transition;

	@include breakpoint-up(md) {
		left: 39%;
	}
	@include breakpoint-up(lg) {
		left: 42%;
	}
	@include breakpoint-up(xl) {
		left: 45%;
	}

	&:focus {
		transform: translateY(0%);
		z-index: 99;
	}
}
