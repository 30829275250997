$button-size: 3.125rem;
$button-offset-from-bottom: 3.4375rem;
$button-offset-from-right: 1.25rem;

.back-to-top-button {
	background: url('/images/icons/back-to-top.svg') no-repeat center 50%;
	background-color: $primary-color;
	border-radius: 50%;
	bottom: $button-offset-from-bottom;
	box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.05);
	display: inline-block;
	height: $button-size;
	opacity: 1;
	position: fixed;
	right: $button-offset-from-right;
	transition: $default-transition;
	visibility: hidden;
	width: $button-size;
	z-index: 10;

	// Use default browser focus outline styles.
	&:focus,
	&:focus-visible,
	&:focus-within {
		outline: auto;
	}

	&:hover {
		filter: brightness(85%);
		opacity: 1;
		transform: scale(1.07);
	}

	&--is-visible {
		animation: zoom-in 0.3s ease-out;
		opacity: 1;
		visibility: visible;
	}

	&--fade-out {
		opacity: 0.75;
	}
}
