.post-block {
	background-color: $body-background;
	display: flex;
	font-family: $accent-font;
	position: relative;
	width: 100%;

	&__type {
		background-color: rgba(255, 255, 255, 0.8);
		font-size: 0.5625rem;
		font-weight: 500;
		left: 0.5rem;
		letter-spacing: 0.05rem;
		padding: 0.625rem 0.5rem;
		position: absolute;
		text-transform: uppercase;
		z-index: 1;
	}

	&__image-container img {
		display: block;
		object-fit: cover;
		object-position: center;
	}

	&__image-container {
		position: relative;

		/* stylelint-disable-next-line selector-class-pattern */
		&:hover ~ .post-block__details .post-block__title {

			/* stylelint-disable-next-line selector-max-compound-selectors */
			a {
				@extend %content-underline;
			}
		}
	}

	&__image-container-overlay {
		height: 3.125rem;
		left: 50%;
		pointer-events: none;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 3.125rem;
		z-index: 1;
	}

	&__image-container-overlay svg {
		filter: drop-shadow(0 0 0.625rem rgba(0, 0, 0, 0.5));
	}

	&__image-link {
		display: block;
	}

	&__title {
		font-size: 0.875rem;
		letter-spacing: 0.03rem;
		line-height: 1.2857;
		margin: 0.0125rem 0;

		@include breakpoint-up(sm) {
			font-size: 0.875rem;
		}

		@include breakpoint-up(lg) {
			font-size: 1rem;
			line-height: 1.375;
		}

		@include breakpoint-up(xl) {
			font-size: 1rem;
		}

		a {
			-webkit-box-orient: vertical;
			color: $matte-black;
			display: block;
			/* stylelint-disable-next-line value-no-vendor-prefix */
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;

			// Add ellipsis to title when breakpoint is 1024-1365
			@include breakpoint-between(xs, sm) {
				-webkit-line-clamp: 4;
			}
			@include breakpoint-between(sm, md) {
				-webkit-line-clamp: 4;
			}
			@include breakpoint-between(md, lg) {
				height: 3.375rem;
				-webkit-line-clamp: 3;
			}
			@include breakpoint-between(lg, xl) {
				height: 4.125rem;
				-webkit-line-clamp: 3;
			}
			@include breakpoint-up(xl) {
				height: 5.5rem;
				-webkit-line-clamp: 4;
			}

			&:hover {
				@extend %content-underline;
			}
		}
	}

	&__tag {
		a {
			color: $primary-color;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__recipe,
	&__article {
		color: $color-dimgrey;
		display: flex;
		flex-direction: column;
		font-size: 0.625rem;
		font-weight: 500;
		letter-spacing: 0.03rem;
		margin-top: auto;
	}

	&__cooking {
		display: flex;
		justify-content: space-between;
	}

	&__details {
		display: flex;
		flex-direction: column;
		line-height: 0.8888;
		margin: 0.5rem;
		width: 100%;

		@include breakpoint-up(sm) {
			margin: 0.625rem;
		}
	}

	&__tag,
	&__tag a {
		color: $primary-color;
		font-size: 0.5625rem;
		font-weight: 500;
		letter-spacing: 0.05rem;
		line-height: 1.2857;
		margin-bottom: 0.5625rem;
		overflow-wrap: anywhere;
		text-transform: uppercase;
	}

	&__rating {
		svg path {
			fill: $star-rating-color;
			stroke: $star-rating-color;
		}

		.rating-average {
			color: $matte-black;
			font-family: $title-font;
			font-size: 0.75rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0269rem;
			line-height: 1.8333;
			margin: 0 0 0.625rem 0.3125rem;
			position: relative;
			top: -0.0625rem;
		}

		.rating-average-hide {
			display: none;
		}
	}
}

/**
Loader animation
*/
.loader.post-block {
	--loader-bg-colour: #{$color-grey-85};

	.post-block {
		&__image-container {
			animation: pulse ease-in-out 2s infinite;
			background-color: var(--loader-bg-colour);
		}

		&__image-container img {
			background-color: var(--loader-bg-colour);
			border: 0;
			visibility: hidden;
		}

		&__tag {
			animation: pulse ease-in-out 2s infinite;
			background-color: var(--loader-bg-colour);
			display: block;
			height: 1rem;
			width: 35%;
		}

		&__title {
			animation: pulse ease-in-out 2s infinite;
			background-color: var(--loader-bg-colour);
			display: block;
			height: 2.25rem;
		}

		&__author {
			animation: pulse ease-in-out 2s infinite;
			background-color: var(--loader-bg-colour);
			height: 0.825rem;
			width: 75%;
		}
	}
}

@keyframes pulse {
	0%,
	100% {
		opacity: 50%;
	}

	60% {
		opacity: 100%;
	}
}

/*
 * The underline offset appears different in Chrome compared to
 * other browsers. So the property value needs to be reduced
 * specifically just for Chrome to match the appearance on the screen.
 */
/* Chrome version 29 and above */
/* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
@media screen and (-webkit-min-device-pixel-ratio:0)
	and (min-resolution: 0.001dpcm) {
	/* stylelint-disable-next-line selector-class-pattern */
	.post-block__title a:hover,
	.featured-image-title:hover {
		text-underline-offset: 0.15rem;
	}
}
