.shows-navigation {
	&-container {
		margin: 1.6875rem auto;
		text-align: center;
		@include breakpoint-up(xl) {
			margin: 2.0625rem auto;
		}
	}

	&-button {
		@extend %button-primary;
		background-color: $button-primary-color;
		border-color: $body-font-color;
		color: $body-font-color;
		margin-right: 2.375rem;
		min-width: 6rem;
		padding: 0.74rem 1rem;

		@include breakpoint-up(xl) {
			font-size: 0.875rem;
			margin-right: 1rem;
			padding: .8125rem 1.0625rem;
		}

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			border-color: $button-primary-background-color;
		}

		&.current {
			background-color: $button-primary-background-color;
			border-color: $button-primary-background-color;
			color: $button-primary-color;
		}
	}

	&-separator {
		background-color: $color-grey-85;
		display: none;
		height: 0.0625rem;
		margin-bottom: 1.25rem;
		margin-top: 2.5rem;

		@include breakpoint-up(lg) {
			display: block;
		}
	}
}
