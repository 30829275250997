.sponsor-ad-wrapper {
	margin: 0;
	min-height: 0;

	iframe {
		margin-bottom: 0;

		@include breakpoint-up(lg) {
			margin-bottom: 1.5rem;
		}
	}

	.ad-label {
		color: $color-dimgrey;
		display: none;
		font-size: 0.625rem;
		font-weight: 500;
		letter-spacing: 0.05rem;
		line-height: 1.5;
		margin-bottom: 0.625rem;
		margin-top: 2rem;
	}
}
