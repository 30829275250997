.related-content {
	background-color: $related-content-background-color;
	padding: 1rem var(--body-margin) 3.25rem;

	@include breakpoint-up(md) {
		padding: 1.75rem var(--body-margin) 6.125rem;
	}

	@include breakpoint-up(lg) {
		padding: 3.25rem var(--body-margin) 7.5rem;
	}

	.post-block-grid {
		padding: 0;

		&-title {
			margin-bottom: 1.5rem;

			@include breakpoint-between(sm, md) {
				font-size: 1.375rem;
			}

			@include breakpoint-up(lg) {
				margin-bottom: 1.875rem;
			}

			@include breakpoint-up(xl) {
				margin-bottom: 2.5rem;
			}
		}

		.post-block:last-child {

			@include breakpoint-between(md, xl) {
				display: none;
			}
		}
	}

	> div:not(:first-child) {
		margin-top: 4rem;

		@include breakpoint-up(lg) {
			margin-top: 5rem;
		}

		@include breakpoint-up(xl) {
			margin-top: 6.25rem;
		}
	}
}