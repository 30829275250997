%h1-style {
	font-family: $title-font;
	font-size: 1.625rem;
	font-weight: $font-weight-semi-bold;
	letter-spacing: 0.0313rem;
	line-height: 1.3846;

	@include breakpoint-up(md) {
		font-size: 2rem;
		letter-spacing: 0.0387rem;
		line-height: 1.3125;
	}

	@include breakpoint-up(lg) {
		font-size: 2.5rem;
		letter-spacing: 0.05rem;
		line-height: 1.3;
	}
}

%h2-style {
	font-family: $title-font;
	font-size: 1.375rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.0938rem;
	line-height: 1.4545;

	@include breakpoint-up(md) {
		font-size: 1.5rem;
		line-height: 1.4166;
	}
}

%h3-style {
	font-family: $title-font;
	font-size: 1.125rem;
	font-weight: $font-weight-semi-bold;
	letter-spacing: 0.075rem;
	line-height: 1.4444;

	@include breakpoint-up(lg) {
		font-size: 1.375rem;
		letter-spacing: 0.0938rem;
		line-height: 1.4545;
	}
}

%h4-style {
	font-family: $title-font;
	font-size: 0.875rem;
	font-weight: $font-weight-bold;
	letter-spacing: 0.0625rem;
	line-height: 1.5714;

	@include breakpoint-up(md) {
		font-size: 1rem;
		line-height: 1.5;
	}
}

%h5-style {
	font-family: $title-font;
	font-size: 0.625rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.08rem;
	line-height: 1.6;
	margin: 1rem 0;

	@include breakpoint-up(lg) {
		font-size: 0.75rem;
	}
}

%body-font {
	font-family: $body-font-family;
	font-size: 1rem;
	font-weight: $font-weight-normal;
	letter-spacing: 0;
	line-height: 1.75;

	@include breakpoint-up(lg) {
		font-size: 1.125rem;
		letter-spacing: 0.019rem;
		line-height: 1.7777;
	}
}

%button-primary {
	align-items: center;
	background-color: $button-primary-background-color;
	border: 0.125rem solid $button-primary-background-color;
	border-radius: 0;
	color: $button-primary-color;
	cursor: pointer;
	display: inline-block;
	font-family: $title-font;
	font-size: 0.75rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.045rem;
	line-height: 1.5;
	padding: 0.625rem 1rem;
	text-align: center;
	text-transform: uppercase;
	transition: background-color $default-transition;

	&:active {
		background-color: $button-primary-color;
		color: $body-font-color;
	}

	@media (hover: hover) {
		&:hover {
			background-color: $button-primary-color;
			color: $body-font-color;
		}
	}
}

%image-caption {
	color: $color-grey-35;
	font-family: $title-font;
	font-size: 0.75rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.0125rem;
	line-height: 2;
	text-align: left;
}

%ad-label {
	color: $ad-label-font-color;
	display: block;
	font-family: $title-font;
	font-size: 0.563rem;
	font-weight: $font-weight-medium;
	letter-spacing: 0.0875rem;
	line-height: 1.1101;
	margin-bottom: 0.875rem;
	text-align: center;
}

%load-more {
	@extend %button-primary;
	background-color: $primary-color;
	border: 0.125rem solid $primary-color;
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: 0.05rem;
	line-height: 1.4285;
	padding: 1.375rem;

	@media (hover: hover) {
		&:hover {
			background-color: $color-amour;
			cursor: pointer;
		}

		&:hover a {
			color: $black-bg-color;
			cursor: pointer;
		}
	}
}

%load-more-link {
	color: $button-primary-color;
}

%content-underline {
	text-decoration-line: underline;
	text-decoration-thickness: 0.125rem;
	text-underline-offset: 0.2rem;
	text-decoration-color: $secondary-color;
}
