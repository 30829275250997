@mixin hide-text {
	text-indent: -99999px;
}

@mixin breakpoint-up($bp) {
	$bp-size: map-get($breakpoints, $bp);
	@if ($bp-size == 0) {
		@content;
	} @else {
		@media (min-width: $bp-size) {
			@content;
		}
	}
}

@mixin breakpoint-between($lower-bp, $upper-bp) {
	$lower-bp-size: map-get($breakpoints, $lower-bp);
	$upper-bp-size: map-get($breakpoints, $upper-bp);
	@if ($lower-bp-size == 0) {
		@media (max-width: ($upper-bp-size - 0.0125rem)) {
			@content;
		}
	} @else {
		@media (min-width: $lower-bp-size) and (max-width: ($upper-bp-size - 0.0125rem)) {
			@content;
		}
	}
}

// Applies a value to a CSS property for each breakpoint in a given map.
@mixin breakpoint-property($map, $property) {
	@each $bp, $value in $map {
		@include breakpoint-up($bp) {
			#{$property}: $value;
		}
	}
}


@mixin add-underline($color, $top: 85%, $bottom: 89%) {
	background-image: linear-gradient(
			180deg, rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0) $top,
			$color $top + 1%,
			$color $bottom,
			rgba(0, 0, 0, 0) $bottom + 1%,
			rgba(0, 0, 0, 0)
		);
	text-decoration: none;
}

@mixin link-hover-slide ($color: $primary-color, $height: 90%) {
	background-image: linear-gradient(180deg, transparent $height, rgba($color, 1) 0);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size $default-transition;

	&:hover,
	&.hover {
		background-size: 100% 100%;
		border-bottom: 0;
	}
}

@mixin focus-outline($color, $width: 0.125rem) {
	&:focus {
		border-radius: 0.0625rem;
		box-shadow: 0 0 0 $width $color;
		outline: 0;
	}

	&:focus:not(:focus-visible) {
		box-shadow: unset;
		outline: 0;
	}

	&:focus-visible {
		border-radius: 0.0625rem;
		box-shadow: 0 0 0 $width $color;
		outline: 0;
	}
}

$arrow-directions: (
	right: 45deg,
	down: 135deg,
	left: 225deg,
	up: 315deg
);

// CSS arrow mixin (i.e. empty right triangle, or > character). Uses a square with a border.
@mixin css-arrow($direction: right, $line: 1px solid $body-font-color, $size: 30px, $absoluteCentered: false) {
	$pos-offset: null;

	@if ($absoluteCentered) {
		$pos-offset: translate(-50%, -50%);
		left: 50%;
		position: absolute;
		top: 50%;
	}

	border-right: $line;
	border-top: $line;
	display: inline-block;
	height: $size;
	transform: $pos-offset rotate(#{map-get($arrow-directions, $direction)}) translate(-16.7%, 16.7%);
	width: $size;
}

@mixin underline-text {

	text-decoration: underline;
	text-decoration-color: $primary-color;
	text-underline-offset: 0.0625rem;
	transition: $default-transition;
	-webkit-text-decoration-color: $primary-color;
	-webkit-text-decoration-line: underline;
	-webkit-text-decoration-thickness: 0.0625rem;
	-webkit-transition: $default-transition;

	&:hover {
		color: $primary-color;
	}
}

@mixin card-description {
	color: $matte-black;
	font-family: $accent-font;
	font-size: 0.75rem;
	font-weight: $font-weight-light;
	letter-spacing: 0.0338rem;
	line-height: 2;
}
