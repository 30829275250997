.video-item {
	display: block;
	outline: none;
	overflow: hidden;
	transition: 0.3s;

	&-image-container {
		position: relative;
	}

	&:hover .overlay {
		opacity: 0.35;
	}

	&:focus-visible {
		box-shadow: 0 0 0 0.125rem $body-background;
	}

	.overlay {
		background-color: #000;
		bottom: 0;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: 0.3s ease-in;
		width: 100%;
	}

	img {
		display: block;
		width: 100%;
	}
}

.video-item-title {
	color: $body-background;
	display: block;
	font-family: $title-font;
	font-size: 0.875rem;
	font-weight: $font-weight-semi-bold;
	letter-spacing: 0.06rem;
	line-height: 1.4285;
	padding-top: 0.75rem;
}
