.recipes {
	&-header {
		background-image: linear-gradient($body-background 0%, $body-background 50%, $color-amour 100%);
		padding: 0 var(--body-margin) 1.25rem;

		@include breakpoint-up(lg) {
			padding-bottom: 3.125rem;
		}

		&-separator {
			background-color: $color-grey-85;
			display: none;
			height: 0.0625rem;
			margin-bottom: 1.25rem;
			margin-top: 3rem;
			
			@include breakpoint-up(lg) {
				display: block;
			}
		}
	}

	&-title {
		letter-spacing: 0.1119rem;
		margin: 1.675rem 0;
		text-align: center;
		text-transform: uppercase;

		@include breakpoint-between(xs, sm) {
			margin-top: 2.5rem;
		}
		@include breakpoint-between(sm, lg) {
			margin-top: 3.75rem;
		}

		@include breakpoint-up(md) {
			letter-spacing: 0.1719rem;
		}
		@include breakpoint-up(lg) {
			margin-bottom: 4rem;
		}
	}

	&-block {
		background-color: $color-amour;
		display: flex;
		flex-direction: column;
		padding-bottom: 2.5rem;

		@include breakpoint-up(lg) {
			padding-bottom: 3.375rem;
		}

		&-footer {
			margin: 120px auto 80px;
		}

		&-loadmore-button {
			@extend %load-more;
		}
	}

	.no-results {
		background-color: $color-amour;
		font-family: $body-font-family;
		font-size: 1rem;
		letter-spacing: 0.0313rem;
		margin: 0;
		padding: 3.25rem var(--body-margin) 19rem;
		text-align: center;
	}
}
