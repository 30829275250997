.recipe-filters-mobile {
	display: block;
	padding: 0;

	@include breakpoint-up(lg) {
		display: none;
	}

	&-button {
		border: 1px solid $recipe-filter-color;
		color: $recipe-filter-color;
		display: flex;
		flex-direction: row;
		padding: 0.5rem 0.75rem;

		&-label {
			font-size: 0.6875rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0475rem;
			text-decoration: none;
			text-transform: uppercase;
		}

		&-icon {
			margin: 0;
			margin-left: 0.75rem;
		}
	}
}
