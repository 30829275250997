.recipe-details,
.recipe-description,
.recipe-ingredients,
.recipe-directions,
.recipe-tags,
.bottom-recipe-rating-container {
	margin: 0 var(--body-margin);

	@include breakpoint-up(lg) {
		margin-left: 0;
		margin-right: 0;
	}
}

.recipe-details {
	border-bottom: 0.0625rem solid $color-grey-85;
	border-top: 0.0625rem solid $color-grey-85;
	padding: 0.5rem 0;

	&-item {
		display: flex;
		flex-direction: row;
		font-family: $title-font;
		font-size: 1rem;
		justify-content: flex-start;
		letter-spacing: 0.0375rem;
		line-height: 1.4375;
		margin: 1.25rem 0;

		&-title {
			display: inline-block;
			flex: 0 0 7.5rem;
			font-weight: $font-weight-semi-bold;
			text-transform: uppercase;
		}

		&-value {
			flex: 0 1 auto;
			font-weight: $font-weight-normal;
		}
	}
}

.recipe-ingredients {
	border-top: 0.25rem solid $body-font-color;
	margin-top: 3rem;
	padding: 0.5rem 0;

	@include breakpoint-up(lg) {
		padding: 2rem 0 1rem;
	}

	&-title {
		@extend %h2-style;
		color: $matte-black;
		text-transform: uppercase;
	}
}

.subrecipe {
	margin: 2.5rem 0;

	&:first-of-type {
		margin-top: 2.1875rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&-title {
		@extend %h4-style;
		color: $matte-black;
		letter-spacing: 0.0475rem;
		line-height: 1.4285;
		margin: 0.5rem 0;

		@include breakpoint-up(lg) {
			margin: 1rem 0;
		}
	}

	&-ingredients {
		padding: 0;
	}
}

.subrecipe-ingredients {
	display: grid;
	font-family: $body-font-family;
	font-size: 1rem;
	font-weight: $font-weight-medium;
	grid-column-gap: 0.9375rem;
	grid-row-gap: 0.5rem;
	grid-template-columns: max-content auto;
	line-height: 2;
	list-style-type: none;
	margin: 1rem 0;

	@include breakpoint-up(lg) {
		grid-column-gap: 1.375rem;
	}

	&--quantity {
		text-align: right;
	}
}

.ingredient-substitution-link {
	padding: 0;

	@include breakpoint-up(lg) {
		padding: 1.443rem 0;
	}

	a {
		color: $primary-color;
		font-family: $accent-font;
		font-size: 1rem;
		letter-spacing: 0.45px;
		text-decoration: underline;
	}
}

.recipe-directions {
	border-top: 0.0625rem solid $color-grey-85;
	overflow: auto;

	@include breakpoint-up(lg) {
		margin-top: 2.625rem;
	}

	&-title {
		margin: 2rem 0;
		text-transform: uppercase;
	}

	&-image {
		margin: 2rem auto;
		max-width: 53rem;

		@include breakpoint-up(lg) {
			margin: 2rem 0;
			max-width: 100%;
		}
	}
}

.recipe-notes {
	&-title {
		@extend %h3-style;
		color: $matte-black;
		font-size: 1rem;
		margin: 0.75rem 0;
	}

	&-text {
		font-size: 1rem;
		letter-spacing: 0.0125rem;
		margin: 0.75rem 0;

		@include breakpoint-up(lg) {
			line-height: 1.625;
		}
	}
}

.recipe-step {
	&-title {
		@extend %h3-style;
		color: $matte-black;
		font-size: 1rem;
		margin-top: 2rem;
	}

	&-content {
		color: $matte-black;
		font-family: $body-font-family;

		p {
			font-size: 1rem;
			letter-spacing: 0.0125rem;
			line-height: 1.625;
			margin: 0.5rem 0;
		}

		img {
			margin: 1.25rem auto 1rem;

			@include breakpoint-up(lg) {
				margin: 2.5rem auto 1rem;
			}
		}
	}

	&-image {
		margin: 1.25rem auto 0;

		@include breakpoint-between(md, lg) {
			max-width: 53rem;
		}

		@include breakpoint-up(lg) {
			margin: 2.5rem auto 0;
		}
	}

	img {
		max-width: 100%;
		text-align: center;

		@include breakpoint-between(md, lg) {
			max-width: 53rem;
			width: auto;
		}
	}
}

.recipe-ratings {
	overflow: hidden;

	.hide {
		display: none;
	}

	.show {
		display: block;
	}
}

.ad-wrapper.recipe-details-ad {
	margin: 4.375rem auto;
}

.bottom-recipe-rating-container {

	border-bottom: 0.0625rem solid $color-grey-85;
	border-top: 0.0625rem solid $color-grey-85;
	margin-bottom: 5.5rem;
	padding-bottom: 3.625rem;

	@include breakpoint-up(xs) {
		padding-bottom: 2.7281rem;
	}

	h2 {
		margin-top: 2.125rem;
		text-transform: uppercase;

		@include breakpoint-up(xs) {
			margin-bottom: 1.875rem;
		}
	}

	.recipe-ratings {
		align-items: center;
		display: flex;
		flex-direction: column;

		.rate-recipe-summary {
			.star {
				cursor: pointer;
				margin: 0 0.625rem;

				@include breakpoint-up(xs) {
					height: 2.0531rem;
					width: 2.0531rem;
				}

				@include breakpoint-up(sm) {
					height: 2.5625rem;
					width: 2.5625rem;
				}

				@include breakpoint-up(xl) {
					margin: 0 0.75rem;
				}

				&:first-child {
					padding-left: 0;
				}
			}

			svg {
				stroke-width: 0.1563rem;
			}
		}
	}

	.recipe-title {
		color: $matte-black;
		font-family: $title-font;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0688rem;
		margin-bottom: 2.1875rem;

		@include breakpoint-up(xs) {
			font-size: 1rem;
			letter-spacing: 0.05rem;
			line-height: 1.625;
			text-align: center;
		}

		@include breakpoint-up(sm) {
			font-size: 1.375rem;
			line-height: 1.3636;
			max-width: 42.1875rem;
		}
	}

	.star-info-message {
		font-family: $title-font;
		font-size: 0.875rem;
		font-weight: $font-weight-medium;
		margin-top: 1.3531rem;
	}

	.submit-cancel {
		display: flex;
		justify-content: center;
		margin-top: 4rem;
		width: 100%;

		@include breakpoint-up(xs) {
			margin-top: 2.6875rem;
		}

		.cancel {
			background-color: $button-nav-background-color;
			border: 0.125rem solid $black-bg-color;
			border-radius: 0.0625rem;
			color: $button-primary-color;
			font-family: $title-font;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0437rem;

			@include breakpoint-up(xs) {
				height: 3.4375rem;
				margin-right: 0.7813rem;
				width: 33%;

			}

			@include breakpoint-up(sm) {
				height: 4rem;
				margin-right: 1.5313rem;
				width: 9.625rem;
			}

			&:hover {
				background-color: $body-background;
				color: $black-bg-color;
			}

		}

		.submit {
			background-color: $primary-color;
			border: 0.125rem solid $primary-color;
			border-radius: 0.0625rem;
			color: $body-background;
			font-family: $title-font;
			font-weight: $font-weight-medium;
			letter-spacing: 0.0437rem;

			@include breakpoint-up(xs) {
				height: 3.4375rem;
				width: 66%;
			}

			@include breakpoint-up(sm) {
				height: 4rem;
				width: 14.8125rem;
			}

			&:hover {
				background-color: $body-background;
				color: $black-bg-color;
			}

			&.loading {
				background-color: $button-nav-background-color;
				border: 0.125rem solid $black-bg-color;
				border-radius: 0.0625rem;
				color: $button-primary-color;
				cursor: not-allowed;
				pointer-events: none;
				position: relative;

				&::after {
					background-color: $button-nav-background-color;
					content: 'Submitting';
					height: 3.4375rem;
					left: 0;
					line-height: 3.4375;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}
		}
	}

	.thank-you-feedback {
		font-family: $title-font;
		font-size: 1.0625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0919rem;
		margin-top: 2.4781rem;
	}
}

// If article tags component is absent, add more margin-top.
div.recipe-directions + div.bottom-recipe-rating-container {
	margin-top: 5.5rem;
}

.rate-recipe-summary {
	display: flex;
	justify-content: center;

	.rating {
		.star {
			height: 1.375rem;
			width: 1.375rem;

			@include breakpoint-up(xl) {
				height: 1.75rem;
				width: 1.75rem;
			}
		}

		.rating__full svg path {
			fill: $star-rating-color;
		}

		svg path {
			fill: $star-rating-color;
			stroke: $star-rating-color;
		}

		.rating__half svg path:nth-child(1) {
			fill: $star-rating-color;
		}

	}

	.rating-average {
		color: $matte-black;
		font-family: $title-font;
		font-size: 1.5rem;
		font-weight: $font-weight-semi-bold;
		letter-spacing: 0.0313rem;
		line-height: 0.9166;
		margin: 0 0 0 0.625rem;
		position: relative;
		top: -0.0625rem;

		@include breakpoint-up(xl) {
			font-size: 1.625rem;
			line-height: 2;
			margin: 0 0 0 0.625rem;
			top: -0.125rem;
		}
	}

	.rating-average-hide {
		display: none;
	}

	.total-votes {
		bottom: 0.125rem;
		color: $color-dimgrey;
		font-family: $title-font;
		font-size: 0.625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.0269rem;
		line-height: 1.5;
		margin: 0 0 0 0.375rem;
		position: relative;

		@include breakpoint-up(xl) {
			bottom: -0.475rem;
			margin: 0 0 0 0.625rem;
		}
	}
}

.rating-cta {
	font-family: $title-font;
	font-size: 0.563rem;
	font-weight: 500;
	letter-spacing: 0.0338rem;
	line-height: 1.3321;
	margin-top: 0.75rem;
	text-transform: uppercase;

	@include breakpoint-up(xs) {
		margin-bottom: 2.125rem;
	}

	@include breakpoint-up(sm) {
		margin-bottom: 2.75rem;
	}

	.arrow-down {
		font-size: 0.8438rem;
		margin-left: 0.6875rem;
	}
}
