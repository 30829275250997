.chefs-hosts,
.shows {
	background-image: linear-gradient($body-background 0%, $related-content-background-color 20%);


	h1 {
		color: $matte-black;
		font-family: $accent-font;
		font-size: 1.625rem;
		font-weight: $font-weight-medium;
		letter-spacing: 0.1119rem;
		line-height: 1.4615;
		margin: 2.9375rem 0 4.1875rem;
		padding: 0;
		text-align: center;
		text-transform: uppercase;

		@include breakpoint-up(xl) {
			font-size: 2.5rem;
			letter-spacing: 0.1719rem;
			line-height: 1.45;
			margin: 7.375rem 0 7.8125rem;
		}
	}

	&__grid {
		display: grid;
		/*
		Layout rows in the following repeating patterns:

		Mobile and up:
		- 3 rows of Chefs & Hosts blocks, followed by 1 ad row
		- each row has 1 grid item
		- no offset

		|chef|
		|chef|
		|chef|
		|-ad-|
		|chef|
		...etc

		Tablet and up:
		- 2 rows of Chefs & Hosts blocks, followed by 1 ad row
		- each row has 2 items
		- starts with an offset of 1 row

		|chef|chef|
		|chef|chef|
		|---ad----|
		|chef|chef|
		...etc

		Desktop and up:
		- 2 rows of Chefs & Hosts blocks, followed by 1 ad row
		- starts with an offset of 1 row

		|chef|chef|chef|
		|------ad------|
		|chef|chef|chef|
		|chef|chef|chef|
		|------ad------|
		|chef|chef|chef|
		|chef|chef|chef|
		|------ad------|
		|chef|chef|chef|
		...etc.
		
		*/

		// Mobile row layout
		grid-auto-rows: 1fr 1fr 1fr auto;

		@include breakpoint-up(md) {
			// Tablet and desktop row layout - starts offset by one row
			grid-auto-rows: 1fr auto 1fr 1fr auto 1fr;
			grid-gap: 2.5rem 0.875rem;
			grid-template-columns: repeat(2, 1fr);
			margin: 0 var(--body-margin);
			padding-bottom: 4.625rem;

			.chefs-hosts-ad-1,
			.shows-ad-1 {
				grid-column: 1 / 3;
				grid-row: 2;
			}

			.chefs-hosts-ad-2,
			.shows-ad-2 {
				grid-column: 1 / 3;
				grid-row: 5;
			}

			.chefs-hosts-ad-3,
			.shows-ad-3 {
				grid-column: 1 / 3;
				grid-row: 8;
			}

			.chefs-hosts-ad-4,
			.shows-ad-4 {
				align-self: center;
				grid-column: 2 / 2;
				grid-row: 11;
			}
		}

		@include breakpoint-up(xl) {
			display: grid;
			grid-column-gap: 1rem;
			grid-row-gap: 2.1875rem;
			grid-template-columns: repeat(3, 1fr);
			margin: unset;
			padding: 0 var(--body-margin) 7.8125rem;
		}

		.ad-wrapper {
			margin: 0 0 2.8125rem;
			padding: 1.375rem 0;

			@include breakpoint-up(md) {
				margin: 0;
			}

			@include breakpoint-up(xl) {
				margin: 3.125rem 0 6.25rem;
				padding: 0;
			}
		}

		.chefs-hosts-ad-1,
		.shows-ad-1 {
			@include breakpoint-up(xl) {
				grid-column: 1 / 4;
				grid-row: 2;
			}
		}

		.chefs-hosts-ad-2,
		.shows-ad-2 {
			@include breakpoint-up(xl) {
				grid-column: 1 / 4;
				grid-row: 5;
				margin: 4.6875rem 0 6.25rem;
			}
		}

		.chefs-hosts-ad-3,
		.chefs-hosts-ad-4,
		.shows-ad-3,
		.shows-ad-4 {
			@include breakpoint-up(xl) {
				display: none;
			}
		}
	}

	&__grid__item {
		display: flex;
		flex-direction: column;
		line-height: 0;
		padding: 0 var(--body-margin) 2.5rem;

		@include breakpoint-up(md) {
			padding: 0;
		}

		@include breakpoint-up(xl) {
			height: auto;
			padding: 0;
			width: 26.0625rem;
		}

		.details {
			background-color: $body-background;
			border-left: 0.125rem solid transparent;
			height: 100%;
			margin: 0;
			min-height: 9.0625rem;
			padding: 1.875rem;
			width: 100%;

			a {
				display: inline-block;
				line-height: 1.5;
			}

			.name {
				color: $matte-black;
				font-family: $accent-font;
				font-size: 1.125rem;
				font-weight: $font-weight-semi-bold;
				letter-spacing: 0.04rem;
				margin: 0;
			}

			.show-tags {
				display: flex;
				flex-wrap: wrap;
				list-style-type: none;
				margin: 0.3125rem 0 0;
				padding: 0;

				a {
					@include card-description();

					text-transform: capitalize;

					&:hover {
						color: $primary-color;
					}
				}

				span {
					text-decoration: underline;
					text-decoration-color: $body-font-color;

					&:hover {
						text-decoration-color: $primary-color;
					}
				}
			}
		}
	}

	// Hover styles
	.hover-style--true {
		border-left: 0.125rem solid $primary-color;
		transition: all 0.2s ease-in;
	}

	.profile-img-link {
		display: block;

		&:hover ~ .details {
			@extend .hover-style--true;
		}
	}
}
