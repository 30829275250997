.VideoPlayer {
	font-family: $title-font;
	height: 100%;
	position: relative;
	z-index: 1;

	&--vertical {
		margin: auto;

		@include breakpoint-up(sm) {
			max-width: 17.4375rem;
		}

		.jw-player-video-duration {
			bottom: 1rem;
			font-size: 0.625rem;
			left: 0;
			letter-spacing: 0.0625rem;
			padding: 0 .9375rem;
		}

		.jw-state-idle {
			.jw-player-video-duration {
				display: block !important;
			}

			.jw-controls {
				background: none !important;
			}
			.jw-title.jw-reset-text {
				bottom: 2.3125rem !important;
				display: block;
				height: unset;
				padding: 0 1rem;
				top: unset !important;
				-webkit-transform: translateZ(0) !important;
			}

			.jw-title-primary.jw-reset-text {
				padding: 0 !important;
				width: 100% !important;
			}
		}

		.jw-state-paused {
			.jw-title.jw-reset-text {
				bottom: 4rem !important;
				display: block;
				height: unset;
				padding: 0 1rem;
				top: unset !important;
				-webkit-transform: translateZ(0) !important;
			}
			.jw-title-primary {
				display: none;
			}
			.jw-title-secondary.jw-reset-text {
				padding: 0 !important;
				width: 100% !important;
			}
		}

		.jw-title-primary,
		.jw-title-secondary {
			font-size: 0.875rem !important;
			letter-spacing: 0.0156rem !important;
			line-height: 1.25rem !important;
			padding: 0 0.9375rem !important;
		}

		.jw-state-paused.jw-flag-fullscreen {
			.jw-title {
				top: 1.875rem !important;
			}
		}

		.jw-flag-ads-googleima.jw-flag-small-player .jw-text {
			bottom: 4.375rem;
			left: 0.3125rem;
			margin: 0 !important;
			position: absolute;
		}

		.jw-flag-ads {
			.jw-controlbar .jw-text-alt {
				display: none;
			}
		}

		.adBlockerMessage {
			min-height: 39.75rem;

			@include breakpoint-up(sm) {
				min-height: 31rem;
			}

			.videoErrorMessage-copy {
				padding: 0 1.25rem !important;
			}
		}
	}

	.VideoPlayer-playerContainer {
		height: 100%;
		position: relative;

		.jw-title {
			// Ensure title goes overtop of overlay.
			background: none;
			z-index: 1001;
		}

		.videoErrorMessage-copy {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.adBlockerMessage {
			width: 100%;

			.videoErrorMessage-copy {
				background: rgba(0, 0, 0, 1);
				padding: 0 8%;

				@include breakpoint-up(md) {
					padding: 0 15%;
				}

				.icon-face {
					display: block;

					&::after {
						color: $secondary-color;
						font-size: 2.25rem;
						padding-bottom: 1.125rem;

						@include breakpoint-up(md) {
							font-size: 3.25rem;
							padding-bottom: 2.5rem;
						}
					}
				}

				.icon-ctrl {
					display: none;
				}

				&-title {
					font-family: $title-font;
					font-size: 1rem;
					font-weight: $font-weight-medium;
					letter-spacing: 0.0375rem;
					line-height: 1.4375;

					@include breakpoint-up(md) {
						font-size: 1.5rem;
						letter-spacing: 0.0625rem;
						line-height: 1.4444;
					}
				}

				.videoErrorMessage-cta-wrapper {
					display: none;
				}

				.videoErrorMessage-copy-message {
					font-family: $body-font-family;
					font-size: 0.75rem;
					letter-spacing: 0.0387rem;
					line-height: 1.5833;
					margin: 0 auto;
					max-width: 21.875rem;
					padding: 0.3125rem 0;
					text-shadow: 0.125rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.5);

					@include breakpoint-up(md) {
						font-size: 1rem;
						letter-spacing: 0.0512rem;
						line-height: 1.75;
						padding: 0.625rem 0;
					}
				}
			}
		}
	}

	.state--error .VideoPlayer-inner--loading,
	.jw-error + .VideoPlayer-inner--loading,
	.jwplayer:not(.jw-state-setup) + .VideoPlayer-inner--loading {
		// A little fragile. Spinner must be adjacent to player
		// for loading state to hide properly.
		display: none;
	}

	.jwplayer .welcomeBackOverlay {
		h3 {
			font-size: 1.875rem;
			line-height: 1;
			margin-bottom: 1.5rem;
		}

		p {
			font-size: 1.25rem;
			margin-bottom: 3.125rem;
		}

		.button {
			@extend %button-primary;
			margin: 0 1.5rem;

			@include breakpoint-up(sm) {
				font-size: 1rem;
				padding: 1.125rem 1.875rem;
			}
		}
	}
}

.jw-skin-corus {
	&.jw-breakpoint-2,
	&.jw-breakpoint-3 {
		.jw-title-primary {
			font-size: 1rem;
		}

		.jw-title-secondary {
			font-size: 0.85rem;
		}
	}

	.jw-nextup-container {
		max-width: 28.125rem;
	}

	&.jw-state-idle {
		.jw-player-video-duration {
			display: block !important;
		}
	}
}

.jwplayer .countdown-overlay .countdown-cancel {
	outline: none;
}

.jwplayer-messaging {
	height: 100%;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1000;

	// Apologies for this query, it's taken from JWPlayer. Hides while playback is active.
	.jw-flag-user-inactive.jw-state-playing:not(.jw-flag-media-audio):not(.jw-flag-audio-player):not(.jw-flag-ads-vpaid-controls):not(.jw-flag-casting) ~ & {
		opacity: 0;
		visibility: hidden;
	}

	.videoErrorMessage.message--videoNotFound {

		height: 100%;

		&::before {
			background: #000;
		}

		.videoErrorMessage-copy-title {
			font-family: $title-font;
			font-size: 1rem;
			font-weight: $font-weight-semi-bold;
			letter-spacing: 0.0356rem;
			line-height: 1.4375;
			padding-bottom: 1rem;

			@include breakpoint-up(md) {
				font-size: 1.125rem;
				letter-spacing: 0.04rem;
				line-height: 1.4444;
				padding-bottom: 1.25rem;
			}
		}

		.videoErrorMessage-copy-message {
			font-family: $body-font-family;
			font-size: 0.75rem;
			line-height: 1.6666;
			max-width: 26rem;
			padding: 0 1rem;

			@include breakpoint-up(md) {
				font-size: 1rem;
				line-height: 1.375;
			}
		}
	}
}

.VideoPlayer-loading {
	animation: pulse 1.75s ease-in infinite;
	background: $loader-bg-color;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 10;
}

.state--error .VideoPlayer-loading,
.jw-error + .VideoPlayer-loading,
.jwplayer:not(.jw-state-setup) + .VideoPlayer-loading {
	// A little fragile. Spinner must be adjacent to player
	// for loading state to hide properly.
	display: none;
}

.jw-player-video-duration {
	animation: zoom-in 0.3s forwards;
	bottom: 1rem;
	color: $body-background;
	font-size: 0.75rem;
	font-weight: $font-weight-medium;
	left: 1rem;
	line-height: 1.3333;
	position: absolute;

	@include breakpoint-up(sm) {
		bottom: 1.75rem;
		font-size: 0.875rem;
		left: 1.75rem;
	}
}

// Not a defined breakpoint, so handle manually
@media (min-width: 28.25rem) {
	.jw-player-video-duration {
		bottom: 1.75rem;
		left: 1.75rem;
	}
}

.video-shortcode-embed {
	.article-video {
		margin: 2rem 0;
	}
}
