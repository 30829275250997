.main {
	display: flex;
	flex-direction: column;
	height: auto;
	min-height: calc(100% - #{$mobile-navbar-height});
	overflow: auto;
	position: absolute;
	top: $mobile-navbar-height;
	width: 100%;
	z-index: 0;

	@include breakpoint-up(lg) {
		min-height: calc(100% - 6.625rem);
		overflow: unset;
		position: relative;
	}

	@include breakpoint-up(xl) {
		min-height: calc(100% - #{$desktop-navbar-height});
	}

	.content {
		flex: 1 1 auto;
		position: relative;
	}
}
